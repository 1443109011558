<template>
  <div class="main-wrap">
    <!-- 搜索 -->
    <search-form @search="search" :show-exam="false"></search-form>
    <!-- 黑名单表 -->
    <black-table
      :list="list"
      @edit="handleEdit"
      @view="handleView"
      @sortChange="handleSort"
    ></black-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="currentPage"
    ></el-pagination>
    <!-- 查看 -->
    <view-bar :show-view="showView" @close="closeView"></view-bar>
  </div>
</template>

<script>
// components
import searchForm from "../components/search-form";
import blackTable from "./components/black-table";
import viewBar from "@/components/viewBar";

import service from "./api";
import pagination from "@/mixins/pagination";
import view from "@/components/viewBar/view";
export default {
  mixins: [pagination, view],
  data() {
    return {
      list: [],
      page: {},
      searchData: {},
      currentPage: 1
    };
  },
  components: {
    searchForm,
    blackTable,
    viewBar
  },
  methods: {
    // 初始化
    init(currentPage) {
      let initParams = { pageNum: currentPage, pageSize: 10 };
      return this.getList(initParams);
    },
    // 搜索
    search(data) {
      let params = Object.assign(data, {status:-1, pageNum: 1 });
      this.searchData = data;
      return this.getList(params);
    },
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 解除黑名单
    handleEdit(row) {
      this.$alert(
        `是否确认将用户${row.username || row.mobile}从黑名单移除?`,
        "编辑",
        {
          showCancelButton: true
        }
      ).then(() => {
        return service
          .edit({ userId: row.userId || null, type: 0 })
          .then(res => {
            this.init(this.currentPage);
            this.$message({ type: "success", message: "操作成功！" });
          });
      });
    }
  },
  mounted() {
    this.init(this.currentPage);
  }
};
</script>

<style lang="scss" scoped></style>
