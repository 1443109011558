import API from "@/api/index";

export default {
  getList: param => {
    return API.requestGet({
      params: {
        type: 1,
        status:-1,
        ...param
      },
      url: "user/getList"
    });
  },
  edit: params => {
    return API.requestPost({
      data: params,
      url: "user/ediBlack"
    });
  }
};
